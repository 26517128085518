import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaRegCalendarAlt } from "react-icons/fa";
import { ArrowRight } from "react-bootstrap-icons";
import axios from "axios";
import pdfIcon from "../../assets/svg/icons8-export-pdf-60.png";
import videoIcon from "../../assets/images/icons8-play-video-48.png";

function StudentBoard() {
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const student_id = localStorage.getItem("student_id");
  const class_name = localStorage.getItem("class_name");
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const [board, StudentBoard] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoading = useRef(false);
  const [image, setImage] = useState([]);
  const [total, totalPaid] = useState([]);
  const [pdf1, setPdf] = useState([]);
  const [img1, setImg] = useState([]);
//   const [video, setVideo] = useState([]);

  useEffect(() => {
    if (!isLoading.current) {
      StudentPayments();
    }
  }, []);

  const StudentPayments = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-class-board-list`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      formData.append("student_id", user_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios(config);
      const completeImagePdf = response.data.pdfUrl;
      setPdf(completeImagePdf);
      const completeImg = response.data.videothumbnailImageUrl;
      setImg(completeImg);
      const TotalAmount = response.data.totalPaidAmount;
      const teachersData = response.data.data;
      console.warn(teachersData);
      totalPaid(TotalAmount);
      StudentBoard(teachersData);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

 
  return (
    <div className="App profile fee Pay_online">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Student Board</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Container>
                <Row>
                  <Table  striped bordered hover className="mt-5 table-striped" responsive="xs" size="sm">
                    <thead className="text-center">
                      <tr>
                        <th>Sr</th>
                        <th>Board Name</th>
                        <th>Chapter Pdf</th>
                        <th>Chapter Video</th>
    
                      </tr>
                    </thead>
                    <tbody>
                      {board && board.map((boards) => (
                        <tr className="text-center">
                          <td>{boards.SrNo}</td>
                          <td>{boards.board_name}</td>
                          <td>{boards.pdf_title} <a
                                href={`${pdf1}/${boards.pdf}`}
                                download={boards.pdf}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                               <img src={pdfIcon} style={{ height: "40px" }} alt="Download PDF" />
                              </a> </td>
                          <td> {boards.video_title}  <a
                              href={boards.video_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={`${img1}/${boards.thumbnail_image}`}
                                style={{
                                  height: "40px",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                alt="Play Video"
                              />
                            </a>
                            </td>          
                    
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default StudentBoard;
