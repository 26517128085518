import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
// import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import student from "../../assets/svg/ic_attendance.svg";
import money from "../../assets/svg/ic_fees_due.svg";
import quiz from "../../assets/svg/ic_quiz.svg";
import assignment from "../../assets/svg/ic_assignment.svg";
import holiday from "../../assets/svg/ic_holiday.svg";
import time from "../../assets/svg/ic_calendra.svg";
import result from "../../assets/svg/ic_results.svg";
import date from "../../assets/svg/ic_date_sheet.svg";
import doubt from "../../assets/svg/ic_doubts.svg";
import gallery from "../../assets/svg/ic_gallery.svg";
import leave from "../../assets/svg/ic_leave.svg";
import password from "../../assets/svg/ic_password.svg";
import event from "../../assets/svg/ic_event.svg";
import log from "../../assets/svg/ic_logout.svg";
import { useNavigate } from "react-router-dom";
import Holiday from "./Holiday";
import loader from "../../assets/images/giphy.gif";
import userpro from "../../assets/svg/user-profile-avatar-svgrepo-com.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { LiaSchoolSolid } from "react-icons/lia";
import faculty from '../../assets/svg/faculty_profile.svg'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import history from '../../assets/svg/transaction-history (1).png';
import notific from '../../assets/svg/notificaiton.png';
import board from '../../assets/svg/apprentice.png';


function Dashboard() {
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  // alert(class_id)
  const count = localStorage.getItem("count");
  const token = localStorage.getItem("token");
  const [getuserdata, setdata] = useState([]);
  const [getstudentdata, setStudentData] = useState([]);
  const [getstudentfees, setStudentFee] = useState([]);
  const [getschoolData, setSchoolData] = useState([]);
  const [getclassData, setClassData] = useState([]);
  const stdnotificationCount = localStorage.getItem("stdnotificationCount");
  const [getnotify, setNotify] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notiCount, setNotiCount] = useState([]);
  const [getcount, setCount] = useState(0);
  const isLoading = useRef(false);

  useEffect(() => {
    if (!isLoading.current) {
   loadUser();
    }
    if (user_id == null) {
      navigate("/");
    }
    if (token == null) {
      navigate("/");
    }
    const stdnotificationCount = localStorage.getItem("stdnotificationCount");
    if (stdnotificationCount > stdnotificationCount - 1) {
      localStorage.setItem("stdnotificationCount", 0);
    }
  }, []);

  const loadUser = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}getStudentProfile`;
      const formData = new FormData();
      formData.append("id", user_id);
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      setLoading(true);
      const response = await axios(config);
      localStorage.setItem("class_id", response.data.studentData.class_id);
      localStorage.setItem("section_id", response.data.studentData.section_id);
      localStorage.setItem("name", response.data.studentData.name);
      //  alert(response.data.studentData.name);
      setdata(response.data.data);
      console.warn(response);

      const setstudentData = response.data.studentData;
      setStudentData(setstudentData);
     
      const setstudentfee = response.data.studentData;
      setStudentFee(setstudentfee);

      const setschoolData = response.data.data.school_details;
      setSchoolData(setschoolData);

      const setclassData = response.data.studentData.class_details;
      setClassData(setclassData);

      const leavecount = response.data.student_lave_count;
      setCount(leavecount);

      const getcommunication = response.data.school_student_communication;
      setNotify(getcommunication);


      const completeImageUrl = `${response.data.url}/${response.data.data.profile_image}`;
      setImage(completeImageUrl);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    // Remove the token from local storage
    window.location.href = "/";
    localStorage.clear();
  };

  const handleNotificationClick = () => {
    setCount(0); 
  }


  return (
    <div>
    {loading && (
      <div className="img-fluidloder1">
        {/* <img src={loader} alt="quiz" /> */}
      </div>
    )}
    <div className="App">
      <Container>
        <Row className="pb-1 heading_content">
          <Col md={12} sm={12} xs={12} className="d-flex justify-content-end Deatails">
            <div className="dp1">
              {" "}
              <Link to="/Notification"  onClick={handleNotificationClick}>
              <IconButton aria-label="Notifications">
              <Badge badgeContent={getcount} color="primary">
              <NotificationsIcon />
              </Badge>
              </IconButton>
              </Link>
              
              <img className="img1" src={userpro} alt={""} />
              <DropdownButton className="drop1" id="drop" title="">
                <Link to="/Profile">
                <Dropdown.ItemText>Profile</Dropdown.ItemText>
                </Link>
                <Link to="/Notification">
                <Dropdown.Item as="button">Notification</Dropdown.Item>
                </Link>
                <Link to="/login">
                <Dropdown.Item onClick={handleLogout} as="button">Logout</Dropdown.Item>
                </Link>
              </DropdownButton>
            </div>
          </Col>
          <Col lg={12} xs={12}>
            <Card className="Student_Profile">
              <div className="profile_1">
                <div className="stuImg">
                  <img src={image} alt="Student" />
                </div>
                <div>
                  <p className="UserName">{getuserdata && getuserdata.name}</p>
                  {/* <span className="stuId">Std ID: {getuserdata && getuserdata.id}</span> */}
                  <div className="stuId">Std Email: {getuserdata && getuserdata.email}</div>
                  
                </div>
              </div>
              <div className="Student_detail">
                <div className="details">
                  <p><LiaSchoolSolid /> Academy Details:</p>
                </div>
                <div className="detail_2">
                <p>{getstudentdata && `${new Date(getstudentdata.academic_year_start).getFullYear()}-${new Date(getstudentdata.academic_year_end).getFullYear()}`}</p>
                  <p>{getschoolData && getschoolData.school_name}</p>
                  <p>{getclassData && getclassData.name}th class</p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Row className="gy-3 gx-0 all_cards">
                <Col lg={6} xs={6}>
                  <Link to="/Attendance">
                    <Card className="two_card">
                      <div className="dp1">
                        <img src={student} alt="Student" />
                      </div>
                      <p>{getstudentdata && getstudentdata.attendence}</p>
                      <span>Attendance</span>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Fees">
                    <Card className="two_card">
                      <div className="dp1">
                        <img src={money} alt="Student" />
                      </div>
                      <p>{getstudentfees && getstudentfees.dues_fee}</p>
                      <span>Fees Due</span>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Faculty">
                    <Card className="single_card">
                      <div className="dp1">
                        <img className="faculty_profile" src={faculty} alt="Student" />
                      </div>
                      <p>Faculty profile</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Assignment">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={assignment} alt="Student" />
                      </div>
                      <p>Assignment</p>
                    </Card>
                  </Link>
                </Col>
                {/* <Col lg={6} xs={6}>
                  <Link to="/TotalAttendance">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={assignment} alt="Student" />
                      </div>
                      <p>Attendance Chart</p>
                    </Card>
                  </Link>
                </Col> */}
                
                <Col lg={6} xs={6}>
                  <Link to="/QuizCourse">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={quiz} alt="Student" />
                      </div>
                      <p>Play Quiz</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/TimeTable">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={time} alt="Student" />
                      </div>
                      <p>Time Table</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Result">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={result} alt="Student" />
                      </div>
                      <p>Result</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Doubt">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={doubt} alt="Student" />
                      </div>
                      <p>Ask Doubts</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Application">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={leave} alt="Student" />
                      </div>
                      <p>Leave Application</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Holiday">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={holiday} alt="Student" />
                      </div>
                      <p>School Holiday</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/Events">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={event} alt="Student" />
                      </div>
                      <p>Events</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/School-Notification">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={notific} alt="Student" style={{height:"47px"}}/>
                        {/* <span>{getnotify}</span> */}
                      </div>
                      <p>School Notification</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/PayOnline">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={history} alt="Student" style={{height:"43px"}} />
                        {/* <span>{getnotify}</span> */}
                      </div>
                      <p>Payment History</p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={6}>
                  <Link to="/StudentBoard">
                    <Card className="single_card">
                      <div className="dp1">
                        <img src={board} alt="Student"style={{height:"43px"}} />
                        {/* <span>{getnotify}</span> */}
                      </div>
                      <p>Student Board</p>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
  );
}

export default Dashboard;