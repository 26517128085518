import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { GoClock } from "react-icons/go";
import axios from "axios";
import loader from '../../assets/images/giphy.gif';
import Splashh from "../../assets/images/page404.png";
import { useParams } from 'react-router-dom';

function Events() {
  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const event_id = localStorage.getItem("event_id");
  const token = localStorage.getItem("token");
  const isLoading = useRef(false);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const [image1, setImage1] = useState([]);
  const url = new URL(window.location.href);
  const idParam = url.searchParams.get('id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoading.current = true;
        const url = `${process.env.REACT_APP_API_URL}event-list`;
        const formData = new FormData();
        formData.append('school_id', school_id);
        formData.append('user_id', user_id);
        formData.append('class_id', class_id);

        const config = {
          method: 'POST',
          url: url,
          data: formData,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        };

        const response = await axios(config);
        setUser(response.data.data);
        setLoading(false); // Set loading to false when data is fetched

        const completeImageUrl = `${response.data.url}`;
        setImage(completeImageUrl);
        isLoading.current = false;
      } catch (error) {
        isLoading.current = false;
        console.error("Error fetching user data:", error);
        setLoading(false); // Set loading to false on error
      }
    };
    if(!isLoading.current){
     fetchData();
    }
  }, [user_id, token, class_id, school_id, idParam]);

  const onclickevents = (event_id) => {
    const url = `/eventDetail?id=${event_id}`;
    window.location.href = url;
  };

  return (
    <div className="App profile Event">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={12} xs={12}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Events & Programs</p>
            </div>
          </Col>
        </Row>
        
        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="quiz" />
          </div>
        ) : (
          <Row>
            <Col md={12} sm={12}>
              <div className="round_bg">
                <Container>
                  <Row>
                    {user && user.length > 0 ? (
                      user.map((event) => (
                        <Col key={event.id} md={12} sm={12}>
                          {/* <Link to="/EventDetail"> */}
                            <Card onClick={() => onclickevents(event.id)} className="event bg-image-new">
                              <h6>{event.event_name} Event</h6>
                              <div className="event-box">
                                <div className="dp">
                                  <Card.Img variant="top" src={`${image}/${event.event_image}`} alt={event.event_name}/>
                                  {/* <img src={`https://educationerp.a2logicgroup.com/Admin/backend\\uploads\\School\\event\\multipleImages/${event.event_images}`} alt={event.event_id}/> */}
                                </div>
                                <div className="event_detail">
                                  <Row>
                                    <Col md={6} sm={6} xs={6}>
                                  <p>Start event</p>
                                  <span className="date">
                                    <GoClock />
                                    {new Date(event.event_start_date).toLocaleDateString()}
                                  </span>
                                  </Col>
                                  <Col md={6} sm={6} xs={6}>
                                  <p>End event</p>
                                  <span className="date">
                                    <GoClock />
                                     {new Date(event.event_end_date).toLocaleDateString()}
                                  </span>
                                  </Col>
                                  </Row>
                                  <h6>
                                    {event.event_details}
                                  </h6>
                                </div>
                              </div>
                            </Card>
                          {/* </Link> */}
                        </Col>
                      ))
                    ) : (
                      <Col md={12} className="text-center"> 
                        <img src={Splashh} alt="Gallery_img" className="img-fluid assimg"/>  
                      </Col>
                    )}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default Events;
