import React, { useState, useEffect,useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import loader from "../../assets/images/giphy.gif";
import axios from "axios";
import calendar from '../../assets/svg/calendar-icon-calendar-logo-date-time-icon_761928-109.avif';

function Attendance() {
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const studentuser_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const [Attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoading = useRef(false);
  const [monthss, setMonth] = useState([]);

  useEffect(() => {
    if(!isLoading.current){
     loadTeachers();
    }
  }, []);

  const loadTeachers = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-attendance-percent-list`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      formData.append("student_id", studentuser_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const attendanceMonth = response.data.granPercent;
      const attendanceData = response.data.data;
      console.warn(attendanceData);
      setMonth(attendanceMonth);
      // alert(attendanceMonth);
      setAttendance(attendanceData);
      isLoading.current = false;

    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleButtonClick = ( month, attendance_year,) => {
    localStorage.setItem("month", month);
    localStorage.setItem("year", attendance_year);
    // alert(attendance_year);
  };


  return (
    <div className="App profile fee Assignment">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Attendance Management</p>
            </div>
          </Col>
        </Row>
        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="quiz" />
          </div>
        ) : (
          <Col lg={12} md={12} sm={12} className="pb-5 heading_content">
            <div className="round_bg">
              <Container className="mt-5">
                {/* {.map((teacher) => ( */}
                  <Col
                    
                    className="my-2"
                    lg={12}
                    xs={12}
                  >
                    <Card className="Teacher_Profile">
                      <div className="profile_2">
                        <div className="teacherImg">
                          <img src={calendar}/>
                        </div>

                        <div className="Tprofile">
                          <p className="UserName2">{monthss}</p>
                          <span className="Subject1">
                           Arrange Attendance till date
                          </span>
                        </div>
                      </div>
                    </Card>
                    {/* </Link> */}
                  </Col>
                {/* ))} */}

                <Row className="mt-3">
                 
                    {Attendance && Attendance.map((months)=>(
                    <Col md={4} sm={4} xs={4}>
                       <Link to="/TotalAttendance">
                        <Card className="tcards" onClick={() =>handleButtonClick(months.month, months.attendance_year)}>
                        <h5 className="text-center pt-2">{months.percent}%</h5>
                        <p className="text-center text-lowercase"> {months.month}</p>
                        </Card>
                        </Link>
                    </Col>
                   
                    ))}
                </Row>
              </Container>
            </div>
          </Col>
        )}
      </Container>
    </div>
  );
}

export default Attendance;