import React, { useState, useEffect, useRef} from "react";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import footer from "../../assets/svg/vector_design.svg";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";

function Application() {
  const schoolId = localStorage.getItem("school_id");
  const studentId = localStorage.getItem("user_id");
  const classId = localStorage.getItem("class_id");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [notifications, setNotifications] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    teacher_id: "",
    school_id: schoolId,
    message: "",
    start_date: new Date(),
    end_date: new Date(),
    student_id: studentId,
    class_id: classId,
  });

  const [teachers, setTeachers] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        isLoading.current = true;
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}teacher-lists`;
        const formData = new FormData();
        formData.append("school_id", schoolId);

        const config = {
          method: "POST",
          url: url,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios(config);
        setTeachers(response.data.data);
        console.warn(response.data.data);
        isLoading.current = false;
        setLoading(false);
      } catch (error) {
        isLoading.current = false;
        setLoading(false);
        console.error("Error fetching user data:", error);
      }
    };
    if(!isLoading.current){
     fetchTeachers();
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (name, date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const sendLeaveRequest = async () => {
    try {
      // isLoading.current = true;
      if (!Cookies.get("buttonDisabled")) {
        // Disable the button
        setButtonDisabled(true);
        // Set the cookie to expire in 24 hours
        Cookies.set("buttonDisabled", "true", { expires: 1 }); // 1 day expiration

        const currentCount = parseInt(localStorage.getItem("count")) || 0;
        const newCount = currentCount + 1;
        localStorage.setItem("count", newCount);
        setNotifications(newCount);
        // isLoading.current = false;
      } else {
        // alert('Button is already disabled. Please try again after 24 hours.');
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}student-leave`;

      const formattedData = {
        ...formData,
        start_date: formData.start_date.toISOString().split("T")[0],
        end_date: formData.end_date.toISOString().split("T")[0],
      };

      const response = await axios.post(apiUrl, formattedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status_code === 1) {
        Swal.fire({
          title: "Success",
          text: response.data.message,
          icon: "success",
          timer: 2000,
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "You have already applied for leave today",
          icon: "error",
          timer: 2000, 
        });
      }
    } catch (error) {
      // isLoading.current = false;
      console.error("Error sending leave request:", error);
      Swal.fire(
        "Error",
        "An error occurred while sending the leave request",
        "error"
      );
    } finally {
      // Enable the button after request is processed
      setButtonDisabled(false);
    }
  };

  return (
    <div className="App profile">
      <Container>
        <Row className="heading_content">
          <Col md={12} sm={12} xs={12}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Leave Application</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg Application">
              <Container>
                <Form className="ask_doubt">
                  <Form.Group
                    className="mb-3 w-100"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Class Teacher
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        as="select"
                        className="border-0 border-bottom"
                        name="teacher_id"
                        value={formData.teacher_id}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Teacher</option>
                        {teachers.map((teacher) => (
                          <option
                            key={teacher.teacher_id}
                            value={teacher.teacher_id}
                          >
                            {teacher.teacher_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 w-100"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Application Title
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder=""
                        className="border-0 border-bottom"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Form.Group>

                  <Row>
                  <Col xs={6}>
                    <Form.Group
                      className="mb-3 w-100"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="2">
                        Start Date
                      </Form.Label>
                      <DatePicker
                        selected={formData.start_date}
                        onChange={(date) =>
                          handleDateChange("start_date", date)
                        }
                      />
                    </Form.Group>
                    </Col>
                    <Col xs={6}>
                    <Form.Group
                      className="mb-3 w-100"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="2">
                        End Date
                      </Form.Label>
                      <DatePicker
                        selected={formData.end_date}
                        onChange={(date) => handleDateChange("end_date", date)}
                      />
                    </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group
                    className="mb-3 w-100"
                    controlId="formPlaintextEmail"
                  >
                    <Col sm="12">
                      <Button
                        size="lg"
                        className="mt-5 mb-5 mybutton w-100 d-flex justify-content-center"
                        onClick={sendLeaveRequest}
                        disabled={isButtonDisabled}
                      >
                        Send Request
                        {isButtonDisabled && <Spinner animation="border" />}
                      </Button>{" "}
                    </Col>
                  </Form.Group>
                </Form>
              </Container>
              <div className="footer ">
                <img
                  className="img-fluid w-100"
                  src={footer}
                  alt="footer-bg"
                ></img>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Application;
