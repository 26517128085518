import { React, useState,useEffect,useRef } from "react";
import { Row, Col, Container, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { MdLunchDining } from "react-icons/md";
import axios from "axios";
import loader from '../../assets/images/giphy.gif';

function TimeTable() {

  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const token = localStorage.getItem("token");
  const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [activeDay, setActiveDay] = useState(null);
  const [classtime, setClassTime] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(true); 

  const handleDayClick = (day) => {
    if (day === getCurrentDay()) {
      setActiveDay(day);
    }
  };

  const getCurrentDay = () => {
    const today = new Date();
    const options = { weekday: 'short' };
    return new Intl.DateTimeFormat('en-US', options).format(today).toUpperCase();
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoading.current = true;
        const url = `${process.env.REACT_APP_API_URL}school-teacher-timetable`;
        const formData = new FormData();
        formData.append('school_id', school_id);
        formData.append('class_id', class_id);

        const config = {
          method: 'POST',
          url: url,
          data: formData,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        };

        const response = await axios(config);
        setClassTime(response.data.data);
        setLoading(false); 
         const currentDate = new Date();
         const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
         const currentDay = daysOfWeek[currentDate.getDay()];
         setActiveDay(currentDay);
         isLoading.current = false;
      } catch (error) {
        isLoading.current = false;
        console.error("Error fetching user data:", error);
        setLoading(false); 
      }
    };
    if(!isLoading.current){
  fetchData();
    }
  }, [token, class_id, school_id]);
  return (
    <div className="App profile fee Assignment Timetable">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Time table</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Container>
              {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="quiz" />
          </div>
        ) : (
                <Row>
                 <div>
                    <div className="days">
                      {daysOfWeek.map((day) => (
                        <div
                          key={day}
                          className={`single_day ${activeDay === day ? "active_day" : ""}`}
                          onClick={() => handleDayClick(day)}
                        >
                          <span>{day}</span>
                        </div>
                      ))}
                    </div>  
                </div>
                  
                 
                  <Col md={12} sm={12}>
                    {/* <Card className="due_fees"> */}
                      <div className="fee_card">
                        <Table className="mb-0 timeTable">
                          <thead>
                            <tr>
                              <th className="text-center">Subject Name</th>
                              <th className="text-center">Teacher Name</th>
                              <th className="text-center">Time</th>
                            </tr>
                          </thead>

                          <tbody>
                          {classtime && classtime.length > 0 ? (
                               classtime.map((user, index) => (
                            <tr>
                              <td>{user.subject_name}</td>
                              <td>{user.teacher_name}</td>
                              {/* <td> {user.from_time.split(':').slice(0, 2).join(':')}am - {user.to_time.split(':').slice(0, 2).join(':')}</td> */}
                              <td> {user.from_time} - {user.to_time}</td>
                            </tr>
                           ))
                           ) : (
                             <tr>
                               <td colSpan="7" className="text-center nodataavl Nodata">
                                 No data available or something went wrong.
                               </td>
                             </tr>
                           )}
                          
                          </tbody>
                        </Table>
                      </div>
                    {/* </Card> */}
                  </Col>
                </Row>
             )}   
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TimeTable;
