import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaRegCalendarAlt } from "react-icons/fa";
import { ArrowRight } from "react-bootstrap-icons";
import axios from "axios";
import { ImFilePdf } from "react-icons/im";

function PayOnline() {
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const student_id = localStorage.getItem("student_id");
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const [fee, dueFee] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoading = useRef(false);
  const [image, setImage] = useState([]);
  const [total, totalPaid] = useState([]);

  useEffect(() => {
    if (!isLoading.current) {
      StudentPayments();
    }
  }, []);

  const StudentPayments = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-paid-payments`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      formData.append("student_id", user_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios(config);
      const TotalAmount = response.data.totalPaidAmount;
      const teachersData = response.data.data;

      console.warn(teachersData);
      totalPaid(TotalAmount);
      dueFee(teachersData);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  return (
    <div className="App profile fee Pay_online">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Pay Online</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Container>
                <Row>
                  <Table  striped bordered hover className="mt-5 table-striped" responsive="xs" size="sm">
                    <thead className="text-center">
                      <tr>
                        <th>Sr</th>
                        <th>Student Name</th>
                        <th>Class Name</th>
                        <th>Paid Amount</th>
                        <th>Paid month</th>
                        <th>Fees status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fee.map((feepe) => (
                        <tr className="text-center">
                          <td>{feepe.SrNo}</td>
                          <td className="text-capitalize">{feepe.student_name}</td>
                          <td>{feepe.class_name}th</td>
                          <td>{feepe.paid_amount}</td>
                          <td>{feepe.month}</td>
                          <td className="text-success text-capitalize">{feepe.fees_status}</td>
                        </tr>
                
                      ))}

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                      <td colSpan={3}><b>Total Paid : {total}</b></td>            
                      </tr>
                    </tbody>
                  </Table>

                  <Button
                              size="lg"
                              className="pdfbtn w-100 bg-gray"
                               onClick={() => window.print()}
                            >
                              Download Pdf{" "}
                              <ImFilePdf className="ms-2 fs-5" />
                            </Button>{" "}
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PayOnline;
