import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaDownload } from "react-icons/fa";
import loader from "../../assets/images/giphy.gif";
import { ArrowRight } from "react-bootstrap-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Fees() {
  const navigate = useNavigate();
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const fee_id = localStorage.getItem("fee_id");
  const token = localStorage.getItem("token");
  const [fee, dueFee] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoading = useRef(false);
  const [image, setImage] = useState([]);
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    if(!isLoading.current){
      StudentPayments();
    }
  }, []);

  const StudentPayments = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-dues-payments`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      formData.append("student_id", user_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const completeImageUrl = response.data.url;
      const teachersData = response.data.data;
      // const studentID = response.data.data.student_id;
      // studentIDD(studentID);
      console.warn(teachersData);
      setImage(completeImageUrl);
      dueFee(teachersData);
      isLoading.current = false;
      
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const paymentFees = async (fee_id) => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}online-student-pay-payments`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      formData.append("student_id", user_id);
      formData.append("fee_id", fee_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
         
      const response = await axios(config);
      const data = response.data;
      // alert("Are you sure want to pay payment?, if you pay payment, you can not restore it again, kindly confirm it and then done Payment?");
      if (response.status === 200) {
        if (data.status === 1) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to make the payment? If you proceed, you won't be able to restore it again. Please confirm your action.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I want to proceed",
          cancelButtonText: "No, cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            // User clicked on confirm button
            Swal.fire({
              title: data.message,
              html: `<p><b>Name: ${data.student_name}</b></p> <br>
                     <p><b>Amount: ${data.paid_amount}/-</b></p>`,
              icon: "success"
            });
            // Proceed to PayOnline
            navigate('/PayOnline');
          } else {
            // User clicked on cancel button
            Swal.fire({
              title: "Cancelled",
              text: "Payment process cancelled.",
              icon: "error"
            });
          }
        });
        
        } 
      }
      
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleClick = (fee_id) => {
    localStorage.setItem('fee_id', fee_id);
    window.location.href = "/PayOnline";
  };
  return (
    <div className="App profile fee">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Fees Due</p>
            </div>
          </Col>
        </Row>
        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="quiz" />
          </div>
        ) : (
        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Container>
                <Row>
                  <Col md={12} sm={12}>
                  {fee && fee.length > 0 ?(
                     fee.map((feepe)=>(
                    <Card className="due_fees">
                        <div className="fee_card">
                      <Table hover className="mb-0">
                      <tbody>
                          <tr>
                            <td>Class Name</td>
                            <td>{feepe.class_name}</td>
                          </tr>
                          <tr>
                            <td>Due Date</td>
                            <td>{feepe.installment_date}</td>
                          </tr>
                          <tr>
                            <td>Due Month</td>
                            <td>{feepe.month}</td>
                          </tr>
                          <tr>
                            <td>Due Amount</td>
                            <td>₹{feepe.dues_amount}</td>
                          </tr>
                        </tbody>
                      </Table>
                        </div>
                        {/* <Link to="/PayOnline" className="signin"> */}
                          <Button
                            size="lg"
                            className="mybutton w-100"
                            onClick={() =>paymentFees(feepe.fee_id)}
                                        
                          >
                            PAY NOW
                            <ArrowRight className="ms-2 fs-3 fw-bold" />
                          </Button>{" "}
                        {/* </Link>{" "} */}
                    </Card>
                    ))
                    ) : (
                      <tr>
                        <td
                          colSpan="7"
                          className="text-center nodataavl Nodata1"
                        >
                          No data available or something went wrong.
                        </td>
                      </tr>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        )}
      </Container>
    </div>
  );
}

export default Fees;

