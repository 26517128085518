import { React, useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { GoClock } from "react-icons/go";
import axios from "axios";
import loader from "../../assets/images/giphy.gif";
import found from '../../assets/images/data-found.jpg';

function EventDetail() {
  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const event_id = localStorage.getItem("event_id");
  const token = localStorage.getItem("token");
  const isLoading = useRef(false);
  const [user, setUser] = useState([]);
  const url = new URL(window.location.href);
  const idParam = url.searchParams.get("id");
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoading.current = true;
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}school-events-multiple-images`;
        const formData = new FormData();
        formData.append("school_id", school_id);
        formData.append("event_id", idParam);

        const config = {
          method: "POST",
          url: url,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios(config);
        setUser(response.data.data);
        const completeImageUrl = `${response.data.url}`;
        setImage(completeImageUrl);

        isLoading.current = true;
      } catch (error) {
        isLoading.current = true;
        console.error("Error fetching user data:", error);
      }finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    if (!isLoading.current) {
      fetchData();
    }
  }, [user_id, token]);

   return (
    <div className="App profile gallery">
    <Container>
      <Row className="heading_content">
        <Col md={6} sm={6} xs={6}>
          <div className="heading_right">
            <Link to="/Events">
              <IoIosArrowBack className="fs-2 text-light" />
            </Link>
            <p className="text-light ms-1">Event Details</p>
          </div>
        </Col>
      </Row>

      {loading ? (
        <div className="img-fluidloder">
          <img src={loader} alt="quiz" />
        </div>
      ) : (
        <div className="round_bg">
          {user.length > 0 ? (
            <Row className="gy-3">
              {user.map((event, index) => (
                <Col md={6} sm={4} xs={6} key={index}>
                  <div className="images">
                    <Card.Img
                      variant="top"
                      src={`${image}/${event.event_images}`}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
           <img className="img-fluid" src={found} alt="" srcset="" />
          )}
        </div>
      )}
    </Container>
  </div>
);
}

export default EventDetail;
