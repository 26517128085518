import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import loader from "../../assets/images/giphy.gif";
import axios from "axios";

function SNotification() {
  const school_id = localStorage.getItem("school_id");
  const token = localStorage.getItem("token");
  const [notifications, setNotifications] = useState([]);
  const [details, setDetails] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoading.current) {
      getNotification();
    }
  }, []);

  const getNotification = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-communication`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      console.warn(response.data);
      const leaveData = response.data.data;
      setNotifications(leaveData);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div className="App Attendance holiday">
      <Container>
        <Row className="heading_content">
          <Col md={12} sm={12} xs={12}>
            <div className="heading_right d-flex py-3">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">School Notifications</p>
            </div>
          </Col>
        </Row>

        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="Loading..." />
          </div>
        ) : (
          <Row>
            <Col md={12} sm={12} className="pb-5">
              <div className="round_bg1">
                <Row className=" two_option pl-0">
                  <Col md={12} sm={12}>
                    {notifications && notifications.length > 0 ? (
                      notifications.map((communication, index) => (
                        <Card className="holiday_list p-0 bgimagefortop">
                          {/* <img src="https://cdn4.vectorstock.com/i/1000x1000/71/03/party-background-with-flags-vector-8137103.jpg"/> */}
                          <Card.Body className="pn-5">
                            {/* <Card.Title>{communication && communication.communication_types}</Card.Title> */}
                            <Card.Text>
                              <h1 className="titlecom">
                                {communication && communication.title}
                              </h1>
                              <div className="d-flex justify-content-between py-2">
                                <div className="startdate d-flex">
                                  <small className="d-block">Date :</small>
                                  {communication && communication.date}
                                </div>
                                <div className="enddate  d-flex">
                                  <small className="d-block">Time :</small>
                                  {communication && communication.time}
                                </div>
                              </div>
                              <div className="discriptiondetails">
                                <h6 className="d-block">Description</h6>
                                <p className="desc">
                                  {" "}
                                  {communication && communication.description}
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="7"
                          className="text-center nodataavl Nodata1"
                        >
                          No data available or something went wrong.
                        </td>
                      </tr>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default SNotification;
