import React, { useState, useEffect,useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import loader from "../../assets/images/giphy.gif";
import axios from "axios";

function Faculty_profile() {
  const school_id = localStorage.getItem("school_id");
  const teacher_id = localStorage.getItem("teacher_id");
  const token = localStorage.getItem("token");
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoading = useRef(false);
  const [image, setImage] = useState([]);

  useEffect(() => {
    if(!isLoading.current){
     loadTeachers();
    }
  }, []);

  const loadTeachers = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}teacher-lists`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const completeImageUrl = response.data.url;
      const teachersData = response.data.data;
      console.warn(teachersData);
      setImage(completeImageUrl);
      setTeachers(teachersData);
      isLoading.current = false;
      
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div className="App profile fee Assignment">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Faculty Profile</p>
            </div>
          </Col>
        </Row>
        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="quiz" />
          </div>
        ) : (
          <Col lg={12} md={12} sm={12} className="pb-5 heading_content">
            <div className="round_bg">
              <Container className="mt-5">
                {teachers.map((teacher) => (
                  <Col
                    key={teacher.teacher_id}
                    className="my-2"
                    lg={12}
                    xs={12}
                  >
                    <Card className="Teacher_Profile">
                      <div className="profile_2">
                        <div className="teacherImg">
                          <img src={`${image}/${teacher.profile_image}`} alt={teacher.teacher_name}/>
                        </div>

                        <div className="Fprofile">
                          <p className="UserName1">{teacher.teacher_name}</p>
                          <span className="Subject">
                            {teacher.subject_name}
                          </span>
                        </div>
                      </div>
                    </Card>
                    {/* </Link> */}
                  </Col>
                ))}
              </Container>
            </div>
          </Col>
        )}
      </Container>
    </div>
  );
}

export default Faculty_profile;
